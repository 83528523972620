declare var StudentUpdate: any;
declare var hidePopup: Function;
declare var popup: Function;
declare var toastr: any;

class SecurityQuestionsClass {

    /* Configs */
    securityQuestionsEnabled: boolean = false;
    randomizedSecurityQuestions: any;

    /* Elements */
    last4DigitsMobileNumberElement: HTMLElement | null = null;
    postCodeElement: HTMLElement | null = null;
    studentYearBirthElement: HTMLElement | null = null;

    customSecurityQuestionsCallback: (Function | null) = null;
    loadWizardFunction: Function | null = null;

    selectedSecurityQuestionIndex: number = 0;
    clubId: number = 0;
    updateInvitation: any;
    activeParentEnum: any;
    securityQuestionSecurityStamp: string = "";

    initialize (updateInvitation: any,
                securityStamp: string,
                activeParentEnum: any,
                securityQuestionsEnabled: boolean,
                customSecurityQuestionsCallback: Function) {

        this.updateInvitation = updateInvitation;
        this.securityQuestionSecurityStamp = securityStamp;
        this.activeParentEnum = activeParentEnum;
        this.securityQuestionsEnabled = securityQuestionsEnabled;
        this.customSecurityQuestionsCallback = customSecurityQuestionsCallback;
    }

    validateSecurityQuestions() {
        this.loadWizardFunction!();
    }

    validateSecurityQuestionsCallback(data: any) {
        //hidePopup("#divSecurityQuestions", "#popupBackground");
        this.customSecurityQuestionsCallback!(data);
    }

    clearDialog(closeSecurityDialog?: boolean) {
        $("#loader").hide();

        if (closeSecurityDialog) {
            hidePopup("#divSecurityQuestions", "#popupBackground");
        }
        hidePopup('#divDisplayUserAttend', '#popupBackground');
    }

    loadOrSkipDialog (loadWizardFunction: Function) {
        this.loadWizardFunction = loadWizardFunction;

        if (this.securityQuestionsEnabled) {
            popup("#divSecurityQuestions", "#popupBackground");
        } else {
            this.loadWizardFunction!();
        }
    }

    setNextSecurityQuestion() {
        this.clearDialog();
        toastr.warning("Security Question failed.");
        this.selectedSecurityQuestionIndex++;
        this.refreshSecurityQuestion();
    }

    refreshSecurityQuestion() {
        $("[security-question-id]").hide();
        $("[security-question-id='" +
            this.randomizedSecurityQuestions[this.selectedSecurityQuestionIndex] +
            "']").show();
    }
}

var SecurityQuestions = new SecurityQuestionsClass();