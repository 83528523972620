import Dropzone from "dropzone";
import {get, post} from "axios";
import toastr from "../../../SecurityQuestions/SecurityQuestions";
import {GetDate} from "../../Utilities/DateTimeFields";
import {parse} from "date-fns";
import Logger from "js-logger";

// Initialize the logger
Logger.useDefaults();
if (process.env.NODE_ENV !== "development") {
    Logger.setLevel(Logger.ERROR);
}


// Default 
const EXPIRATION_WARNING_DAYS = 14;

/**
 * Initializes the entire medication section for a specific medical condition. This will look at the opened medical
 * section (in it's unique container), fetch the required data from the data attributes and build the dropzone.
 */
export const InitializeMedications = () => {

    // Clear the create thumbnail because we aren't using it and it's sending off server requests
    Dropzone.prototype.createThumbnailFromUrl = () => {
    };

    // Don't do anything if medication dropzones don't exist
    if (document.getElementById("hfAllowedMedicationPlans")?.value === "false") return;

    const dropzoneElements = document.querySelectorAll(".medication-dropzones");

    for (let [index, dropzoneElement] of dropzoneElements.entries()) {

        const targetDropzone = document.getElementById(dropzoneElement.dataset.targetContainer);
        const medicationIndex = dropzoneElement.dataset.medicationIndex;
        const medicationId = dropzoneElement.dataset.medicationId;
        const medicalId = dropzoneElement.dataset.medicalId;

        InitializeDropzone(dropzoneElement, targetDropzone, medicationIndex, medicationId, medicalId);
    }
};

/**
 * Initializes the dropzone for the medication
 * @param dropzoneElement Dropzone instance
 * @param dropzone Dropzone container
 * @param medicationIndex Index of the medication in the list
 * @param medicationId Medication ID
 * @param medicalId Medical ID that has the medication
 */
const InitializeDropzone = (dropzoneElement, dropzone, medicationIndex, medicationId, medicalId) => {

    const medicationDropzone = new Dropzone(dropzoneElement, {
        url: "/api/StudentProfile/UploadMedication",
        autoProcessQueue: false,
        maxFilesize: 10, // 10MB
        maxFiles: 1,
        acceptedFiles: ".jpg,.jpeg,.png,.pdf,.doc,.docx",
        addRemoveLinks: false,
        dictDefaultMessage: "Drop files here to upload",
        dictRemoveFile: "Remove",
        dictFileTooBig: "File is too big ({{filesize}}MB). Max filesize: {{maxFilesize}}MB.",
        dictInvalidFileType: "You can't upload files of this type.",
        dictMaxFilesExceeded: "You can only upload {{maxFiles}} file.",
        previewTemplate: document.querySelector("#dropzone-preview-template").innerHTML,
        previewsContainer: dropzone,
        init: function () {

            let previousFile;


            this.on("reset", function () {
                const provideLaterCheckbox = document.getElementById(`medication-provide-later-${medicalId}-${medicationIndex}`);
                if (provideLaterCheckbox) {
                    provideLaterCheckbox.parentElement.parentElement.classList.remove("hide");
                }
                this.element.classList.remove("hide");
            });

            this.on("addedfile", function (file) {

                if (typeof previousFile !== "undefined") {
                    this.removeFile(previousFile);
                }

                // Bind the remove button
                const previewElement = this;
                
                //  If the file size > allowed amount, remove the file
                if (file.size >= this.options.maxFilesize * 1024 * 1024) {
                    this.removeFile(file);
                    window.toastr.error("Please ensure files are less than 10MB", `${file.name} is too big`);
                    window.alert(`${file.name} is too big. Please ensure files are less than 10MB`);
                    return;
                }

                MedicationPlanAdded(file, previewElement, dropzone, medicalId, medicationIndex);

                if (!(window.IsAdminInterface ?? false)) {
                    previousFile = file;
                }
            });
        },
    });

    const selectedMedications = window.GetSelectedMedications(medicalId);
    if (medicationId && selectedMedications.includes(medicationId)) {
        CreateMedicationMockFiles(medicationId, medicalId, medicationIndex, medicationDropzone);
    }
}

/**
 * Handle a medication file being added
 * @param  file
 * @param dropzone
 * @param preview
 * @param medicalId
 * @param medicationIndex
 */
const MedicationPlanAdded = (file, dropzone, preview, medicalId, medicationIndex) => {
    
    const isExistingFile = file.isUploaded === true;
    const hasExpiryDate = file.hasExpiryDate === true;
    
    // Only want to allow for one file
    if (dropzone.files.length > 1) {
        dropzone.removeFile(this.files[0]);
    }

    // Add the download button if there is a URL and let them know the file has already been uploaded
    if (file.url) {

        preview.querySelectorAll(".medication-plan-download").forEach((downloadButton) => {
            downloadButton.addEventListener("click", () => {
                window.open(file.url, "_blank");
            });
        });
    }

    // Check if the file has an expiry date and if so, check the checkbox and enter "No Expiry Date" into the date field
    preview.querySelectorAll(".medication-plan-expiry-toggle").forEach((noExpiryToggle) => {
        if (!hasExpiryDate && isExistingFile) {
            noExpiryToggle.checked = true;
        }

        // Hide the "Does not have an expiry date" if the file has an expiry date
        if (hasExpiryDate) {
            noExpiryToggle.parentElement.classList.add("d-none");
        }


        if (isExistingFile && !(window.IsAdminInterface ?? false)) {
            noExpiryToggle.dataset.noExpiryDate = "1";



            // Return false on the click event so nothing happens
            noExpiryToggle.addEventListener("click", (ev) => {
                ev.preventDefault();
                return false;
            });
        }
    });
    
    preview.querySelectorAll(".dz-remove").forEach((removeButton) => {
        removeButton.addEventListener("click", () => {

            // const provideLaterCheckbox = document.getElementById(`medication-provide-later-${medicalId}-${medicationIndex}`);
            // if (provideLaterCheckbox) {
            //     provideLaterCheckbox.parentElement.parentElement.classList.remove("hide");
            // }

            if ((window.IsAdminInterface ?? false)) {
                // Just remove the file
                dropzone.removeFile(file);
            } else {

                // Trigger click on the dropzone element
                dropzone.element.click();
            }

        });
    });
    
    try {
        // Bind the ID to the expiry toggle so we can fetch it
        preview.querySelectorAll(".medication-plan-expiry-toggle").forEach((exoiryToggle) => {
            exoiryToggle.id = `medication-plan-expiry-toggle-${medicalId}-${medicationIndex}`;
        });

        // Bind the ID and add the click listener for ignored plans
        preview.querySelectorAll(".medication-plan-reminder-toggle").forEach((reminderToggle) => {
            reminderToggle.id = `medication-plan-reminder-toggle-${medicalId}-${medicationIndex}`;
            reminderToggle.addEventListener("click", (ev) => {
                window.UserMedicalPartial.AddMedicationPlanProvideLater(medicalId, medicationIndex, ev.target.checked);
            });
            
        });
        
        if (!isExistingFile) {
            // Bind the ID to the expiry date and initialize the date picker as this is a new file
            preview.querySelectorAll(".medication-plan-expiry").forEach((expiryElement) => {
                expiryElement.id = `medication-plan-expiry-${medicalId}-${medicationIndex}`;
                if (!(window.IsAdminInterface)) {
                    window.InitializeDateTimePickerElement(expiryElement);
                } else {
                    window.InitializeDateTimePickerElementAnyDate(expiryElement);
                }


            });
            
        } else {
            // Disable all the elements so users can't change their information
            if (!(window.IsAdminInterface ?? false)) {

                preview.querySelectorAll(".medication-plan-expiry").forEach((expiryElement) => {
                    expiryElement.disabled = true;
                });

                preview.querySelectorAll(".medication-plan-expiry-toggle").forEach((expiryToggle) => {
                    expiryToggle.dataset.noExpiryDate = "true";
                });

            } else {
                // Bind the ID to the expiry date and initialize the date picker as this is a new file
                preview.querySelectorAll(".medication-plan-expiry").forEach((expiryElement) => {

                    // Check if no expiry date
                    if (hasExpiryDate) {

                        window.InitializeDateTimePickerElementAnyDate(expiryElement);
                    } else {
                        expiryElement.readOnly = true;
                        expiryElement.value = "No Expiry Date";
                    }

                });

            }

            
            // Change the label from "Ready to upload" to the medication uploaded date to match the care plan
            preview.querySelectorAll(".medication-plan-uploaded-date").forEach((expiryLabel) => {
                if (file.uploadedDate) {
                    expiryLabel.innerText = `Uploaded on: ${file.uploadedDate}`;
                } else {
                    expiryLabel.innerText = "Uploaded";
                }
            });

            // For the provide later checkbox, let's add a data attribute noExpiryDate if it's already checked

        }

    } catch (e) {
        console.error("Failed to initialize the expiry date");
    }

    // Hide the checkbox to provide later. Access checkbox -> col -> row
    const provideLaterCheckbox = document.getElementById(`medication-provide-later-${medicalId}-${medicationIndex}`);
    if (provideLaterCheckbox) {
        provideLaterCheckbox.parentElement.parentElement.classList.add("hide");
    }

    dropzone.element.classList.add("hide");
    preview.classList.remove("hide");
}


/**
 * Renders the verification status of the medication document
 * @param {HTMLElement} previewElement Container element for the medication document
 * @param {boolean} medicationDocumentVerified Is the medication document verified?
 * @param {number|string} medicationDocumentId ID of the document
 * @param {string} medicationDocumentName Name of the document, used for the download
 * @param {string} verificationDate Verification Date
 */
const RenderVerificationStatus = (previewElement, medicationDocumentVerified, medicationDocumentId, medicationDocumentName, verificationDate) => {

    // Verification button (only applies to admin interface)
    const verificationContainer = previewElement.querySelector(".medication-plan-verification-container");
    if (verificationContainer) {

        if (medicationDocumentVerified) {
            // Generate a verified tick
            const verifiedTick = document.createElement("i");
            verifiedTick.classList.add("fa", "fa-check-circle", "text-success", "fa-2x");
            if (verificationDate) {
                verifiedTick.setAttribute("title", `Verified On: ${verificationDate}`);
            }

            verificationContainer.appendChild(verifiedTick);

        } else {

            // Generate the URL for the file
            const downloadURL = `/StudentUpdate/DownloadDocumentTyped?documentID=${medicationDocumentId}&type=.${medicationDocumentName.split('.').pop()}`;

            // Build a link to open the verification process
            const verifyLink = document.createElement("a");
            verifyLink.href = downloadURL;
            verifyLink.classList.add("btn", "btn-success");
            verifyLink.innerText = "Verify";
            verifyLink.setAttribute("role", "button");
            verifyLink.setAttribute("data-document-id", medicationDocumentId);
            verifyLink.setAttribute("data-download-src", downloadURL);
            verifyLink.setAttribute("data-fancybox", "");

            if (medicationDocumentName.split('.').pop() === "pdf") {
                verifyLink.setAttribute("data-type", "pdf");
            }

            // Append it to the container
            verificationContainer.appendChild(verifyLink);
        }

    }
}

/**
 * Renders the warnings to the user if the document has expired or is expiring
 * @param {boolean} hasExpiryDate Has an expiry date on this medication document
 * @param {HTMLElement} expiryElement Expiry Element
 * @param {HTMLElement} previewElement Container element for the medication document
 */
const RenderExpirationWarnings = (hasExpiryDate, expiryElement, previewElement) => {

    // Check if the document is expiring and show the warning to the user
    if (hasExpiryDate) {

        Logger.debug("Has Expiry Date, rendering warnings...");

        const [isExpiring, isExpired] = isMedicationPlanExpired(expiryElement);

        // Show the warning if the document is expiring
        const expiryWarning = previewElement.querySelector(".inline-warning");
        if (isExpiring) {
            expiryWarning.classList.remove("hide");

            // Add a hide for the no expiry container when the remidner container is shown
            expiryWarning.parentElement.parentElement.querySelector(".medication-plan-expiry-toggle-container").classList.add("hide");
        }

        // Show the error if the document is expired
        const expiryError = previewElement.querySelector(".inline-error");
        if (isExpired) {

            expiryError.classList.remove("hide");

            const canSkipCarePlans = document.getElementById("hfCanSkipCarePlans")?.value === "true";
            const canSkipCarePlanDays = document.getElementById("hfCanSkipCarePlanDays")?.value;

            if (canSkipCarePlans && canSkipCarePlanDays !== 0) {
                const reminderContainers = previewElement.querySelectorAll(".medication-plan-reminder-toggle-container");
                [...reminderContainers].map((reminderContainer) => {

                    // Add a hide for the no expiry container when the remidner container is shown
                    reminderContainer.parentElement.querySelector(".medication-plan-expiry-toggle-container").classList.add("hide");

                    Logger.debug("Showing reminder containers....")
                    reminderContainer.classList.remove("hide");
                    const label = `Remind me in ${canSkipCarePlanDays} day${canSkipCarePlanDays > 1 ? "s" : ""}`;
                    reminderContainer.querySelector(".skip-medication-plan-label").innerText = label;

                    // Add event listener to the checkbox to hide the expiry date field and other checkboxes
                    reminderContainer.querySelector(".medication-plan-reminder-toggle").addEventListener("click", (ev) => {
                        if (ev.target.checked) {

                            expiryError.classList.add("hide");
                            expiryElement.classList.remove("error");
                            previewElement.querySelector(".medication-plan-input-container").classList.add("d-none");
                        } else {
                            expiryError.classList.remove("hide");
                            //expiryElement.classList.add("error");
                            previewElement.querySelector(".medication-plan-input-container").classList.remove("d-none");
                        }
                    });

                });
            }

        }
    }
}

function BindRemoveButton(removeButton, medicationDocumentId, dropzone, file, medicalId, index) {
    removeButton.addEventListener("click", () => {


        //
        // const canSkipCarePlans = document.getElementById("hfCanSkipCarePlans")?.value === "true";
        // const canSkipCarePlanDays = document.getElementById("hfCanSkipCarePlanDays")?.value;
        //
        // const provideLaterCheckbox = document.getElementById(`medication-provide-later-${medicalId}-${index}`);
        // if (provideLaterCheckbox && canSkipCarePlanDays !== 0 && canSkipCarePlans) {
        //     provideLaterCheckbox.parentElement.parentElement.classList.remove("hide");
        // }
    });
}

/**
 * Creates a mock file for the dropzone to display.
 * @param {number|string} medicationId ID of the medication
 * @param {number|string} medicalId ID of the medical condition
 * @param {number|string} index Index of the medication
 * @param {Dropzone} dropzone Dropzone instance
 */
export const CreateMedicationMockFiles = (medicationId, medicalId, index, dropzone) => {


    // Check if the medication Document ID actually exists
    const medicationDocumentId = document.getElementById(`hf-medication-${medicalId}-${medicationId}-document-id`);
    if (!medicationDocumentId) {
        console.debug("No Medication Document ID. Returning...");
        return;
    }

    // Get the remaining fields 
    const medicationDocumentName = document.getElementById(`hf-medication-${medicalId}-${medicationId}-document-name`);
    const medicationDocumentSize = document.getElementById(`hf-medication-${medicalId}-${medicationId}-document-size`);
    const medicationDocumentLink = document.getElementById(`hf-medication-${medicalId}-${medicationId}-document-url`);
    const medicationDocumentExpiry = document.getElementById(`hf-medication-${medicalId}-${medicationId}-document-expiry`);
    const medicationDocumentVerified = document.getElementById(`hf-medication-${medicalId}-${medicationId}-document-verification`);
    const medicationDocumentVerifiedDate = document.getElementById(`hf-medication-${medicalId}-${medicationId}-document-verified-date`);
    const medicationDocumentHasExpiry = document.getElementById(`hf-medication-${medicalId}-${medicationId}-document-has-expiry`);
    const medicationDocumentUploadedDate = document.getElementById(`hf-medication-${medicalId}-${medicationId}-document-uploaded-on`);

    // Create a mock file object
    const mockFile = {
        fileName: medicationDocumentName.value,
        size: medicationDocumentSize.value,
        url: medicationDocumentLink.value,
        type: "image/" + medicationDocumentName.value.split(".").pop()
    };

    Logger.debug("MockFile Creating...", mockFile);
    const hasExpiryDate = medicationDocumentHasExpiry.value === "1";

    // Create the mock file
    // We create a bunch of extra properties on the File Object so we can utilize them on the dropzone events
    const file = new File([""], mockFile.fileName, {type: `${mockFile.type}`});
    Object.defineProperty(file, "size", {"value": mockFile.size}); // Size of the existing file
    Object.defineProperty(file, "url", {"value": mockFile.url}); // URL to download the existing file
    Object.defineProperty(file, "isUploaded", {"value": true}); // Flag to indicate if the file is uploaded
    Object.defineProperty(file, "hasExpiryDate", {"value": hasExpiryDate}); // Flag to indicate if the file has an expiry date
    Object.defineProperty(file, "uploadedDate", {"value": medicationDocumentUploadedDate.value}); // Uploaded date of the file
    Object.defineProperty(file, "isVerified", {"value": medicationDocumentVerified.value}); // Uploaded date of the file

    // Default dropzone files
    file["dataURL"] = mockFile.url;
    file["status"] = "success";

    dropzone.emit("addedfile", file);
    dropzone.emit("success", file);
    dropzone.emit("complete", file);
    
    
    const previewElement = document.getElementById(`medication_plans_existing_${medicalId}_${index}`);
    if (!previewElement) return;
    
    // Disable the expiry date input
    const expiryElement = previewElement.querySelector(".medication-plan-expiry");
    
    if (expiryElement) {

        if (!(window.IsAdminInterface ?? false)) {
            expiryElement.disabled = true;
            expiryElement.addEventListener("click", (ev) => {
                ev.preventDefault();
                return false;
            });
        }


        // Set the value of the input, defaulting to "No Expiry Date" if null. Server will handle parsing
        expiryElement.value = hasExpiryDate ? medicationDocumentExpiry.value : "No Expiry Date";
        if (!hasExpiryDate) {
            expiryElement.readonly = true;
        }


        // Render the appropriate warnings
        // Check if we are in the school page first
        if (document.getElementById("isAdmin")?.value !== "1") {
            RenderExpirationWarnings(hasExpiryDate, expiryElement, previewElement);
        }

        // Define some data attributes so we can process the file later
        expiryElement.dataset.documentId = medicationDocumentId.value;
        expiryElement.dataset.documentName = medicationDocumentName.value;
        expiryElement.dataset.documentSize = medicationDocumentSize.value;
        expiryElement.dataset.documentExtension = medicationDocumentName.value.split('.').pop();
        expiryElement.dataset.hasExpiry = hasExpiryDate ? "1" : "0";
        expiryElement.dataset.medicationId = medicationId;
        
        // Bind the ID to the expiry date so we can fetch it
        expiryElement.id = `medication-plan-expiry-${medicalId}-${index}`;
    }

    // Render the verification tick
    if (medicationDocumentVerifiedDate &&  medicationDocumentVerifiedDate && (window.IsAdminInterface ?? false)) {
        const medicationIsVerified = parseInt(medicationDocumentVerified?.value ?? "0", 10) === 1;
        RenderVerificationStatus(previewElement, medicationIsVerified, medicationDocumentId.value, medicationDocumentName.value, medicationDocumentVerifiedDate.value);
    }


    // Bind the remove button
    const removeButton = previewElement.querySelector(".dz-remove");
    if (removeButton && medicationDocumentId.value) {
        BindRemoveButton(removeButton, medicationDocumentId.value, dropzone, file, medicalId, index);
    }

};

/**
 * Handles the "Does no need an expiry date" checkbox being clicked
 * @param {Event} event Click event
 */
const HandleNoExpiryClicked = (event) => {
    
    if (event.target.dataset?.noExpiryDate) {
        event.target.checked = true;
        return;
    }
    const parentContainer = event
        .target
        .closest(".medication-plan-expiry-toggle-container");

    const noExpiry = event.target.checked;
    const provideLater = parentContainer
        .querySelector(".medication-plan-expiry-toggle")?.checked;
    const showExpiryToggle = provideLater || noExpiry;

    const expiryDateContainer = event.target
        .closest(".medication-plan-expiry-content")
        .querySelector(".medication-plan-expiry-container");

    const expiryDateInput = expiryDateContainer.querySelector(".medication-plan-expiry");

    // Remove the datepicker binding
    if (showExpiryToggle) {
        expiryDateInput.value = "No Expiry Date";
        expiryDateInput.readOnly = true;
        window.DestroyDateTimePickerElement(expiryDateInput);
    } else {

        expiryDateInput.value = "";
        if (!(window.IsAdminInterface)) {
            window.InitializeDateTimePickerElement(expiryDateInput)
        } else {
            window.InitializeDateTimePickerElementAnyDate(expiryDateInput)
        }

        expiryDateInput.readOnly = false;

    }

    const reminderMeContainer = parentContainer.querySelector(".medication-plan-reminder-container");
    reminderMeContainer?.classList.toggle("d-none", provideLater);
}

/**
 * Exposed method for binding the "Does not have an expiry" event on click
 * Bound to the body because we are basing this on a variable element that may or may not exist
 */
export const BindMedicationNoExpiryDateCheckbox = () => {
    document.querySelector("body")
        .addEventListener("click", (event) => {
            // Bind the toggle button
            if (event.target.classList.contains("medication-plan-expiry-toggle")) {
                HandleNoExpiryClicked(event);
            }
        });
}

const isMedicationPlanExpired = (expiryField) => {
    const expiryDate = parse(expiryField.value, "dd/LL/yyyy", new Date());
    const today = new Date();
    const daysUntilExpiry = Math.floor((expiryDate - today) / (1000 * 60 * 60 * 24));
    const isExpiring = daysUntilExpiry <= EXPIRATION_WARNING_DAYS && daysUntilExpiry >= 0;
    const isExpired = daysUntilExpiry < 0;
    
    return [isExpiring, isExpired];

}