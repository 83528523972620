const VALID_FILE_EXTENSIONS = ["txt", "jpg", "jpeg", "gif", "pdf", "doc", "png", "docx", "xls", "xlsx"];

/**
 * Applies the verification status to a verified document
 * @param {HTMLElement} container Container element where the verification status will go 
 * @param {boolean|string} status Status of the verification
 */
export const GetVerificationStatus = (container, status) => {
    
    // Don't do anything if the container doesn't exist
    if (!container) return;
    
    if (status === "true" || status === "1") {
        container.innerHTML = "<span class='text-success'>Verified</span>";
    } else {
        container.innerHTML = "<span class='text-danger'>Awaiting Verification</span>";
    }
    
}


/**
 * Validates the given medical file
 * @param {File} file File object to validate
 * @return {boolean} Is a valid file
 */
export const ValidateFileType = (file) => {
    
    // Get the file extension
    const extension = file.name.split(".").pop();
    
    // Check if the extension is in the list of allowed extensions
    return VALID_FILE_EXTENSIONS.indexOf(extension) !== -1;
    
}


export const GetExistingFilesFromMedical = (container, medicalId) => {
    
    
    
    
}

