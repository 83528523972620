import flatpickr from "flatpickr";
import Logger from "js-logger";
import {parse, format} from "date-fns";

Logger.useDefaults();
if (process.env.NODE_ENV !== "development") {
    Logger.setLevel(Logger.ERROR);
}

const defaultDatepickerConfig = {
    enableTime: false,
    dateFormat: "d/m/Y",
    allowInput: true
    
};

const defaultDateTimePickerConfig = {
    enableTime: true,
    dateFormat: "d/m/Y",
    allowInput: true
};

const defaultTimePickerConfig = {
    enableTime: true,
    noCalendar: true,
    dateFormat: "H:i",
    readonly: true
}

const defaultDateRangePickerConfig = {
    mode: "range",
    maxDate: "today",
    dateFormat: "d/m/Y",
}

const dateFields = {};

/**
 * Initializes a selector to the standard datetime picker, only allowing access to the date
 * @param {string} dateFieldSelector
 * @param {{}} config Additional configurations as outlined in Flatpickr docs
 */
export const InitializeDateFields = (dateFieldSelector, config) => {
    const dateFieldConfig = {...defaultDatepickerConfig, ...config};
    InitializeFields(dateFieldSelector, dateFieldConfig);
};


/**
 * Initializes a selector to the standard time picker, only allowing access to the time
 * @param {string} timeFieldSelector
 * @param {{}} config Additional configurations as outlined in Flatpickr docs
 */
export const InitializeTimeFields = (timeFieldSelector, config = {}) => {
    const timeFieldOptions = {...defaultTimePickerConfig, ...config};
    InitializeFields(timeFieldSelector, timeFieldOptions);
};

/**
 * Updates the flatpickr fields that flow downstream from the source elements, typical use case is a start time updating an empty end time.
 * This allows for the time or date to always be set to at a minimum the start time or date value.
 * @param sourceSelector Elements to be found and iterated over.
 * @constructor
 */
export const UpdateTargetFlatpickrFields = (sourceSelector) => {
    const sourceFields = document.querySelectorAll(sourceSelector);
    sourceFields.forEach((sourceElem, index) => {
        const targetField = sourceElem.dataset.targetField;
        if(targetField === "" || targetField === undefined) {
            Logger.debug(`Failed to find attribute on ${sourceSelector} at index ${index}, attribute is: ${targetField}`)
            return;
        }
        
        // Pick up the flatpickr elements and assign an on close function.
        const targetElem = document.getElementById(targetField);
        const flatPickrStart = document.querySelector(`#${sourceElem.id}`)._flatpickr;
        const flatPickrEnd = document.querySelector(`#${targetElem.id}`)._flatpickr;
        flatPickrEnd.config.onOpen.push(() => {
            flatPickrEnd.config.minDate = flatPickrStart.latestSelectedDateObj;
            !(targetElem.value !== "") ? flatPickrEnd.setDate(flatPickrStart.latestSelectedDateObj) : "";
        })
    });
}

/**
 * Gets the date from a date selector field
 * @param {string} dateFieldSelector Selector for field we want to fetch the date from
 * @return {string} Formatted date
 */
export const GetDate = (dateFieldSelector) => {
    const dateField = document.querySelector(dateFieldSelector);
    const date = parse(dateField.value, "dd/LL/yyyy", new Date());
    return format(date, "yyyy-LL-dd");
};

/**
 * Initializes a selector to the standard datetime picker, only allowing access to the date and time
 * @param {string} dateFieldSelector
 * @param {{}} config Additional configurations as outlined in Flatpickr docs
 */
export const InitializeDateTimeFields = (dateFieldSelector, config) => {
    const dateTimeConfig = {...defaultDateTimePickerConfig, ...config};
    InitializeFields(dateFieldSelector, dateTimeConfig);
};

/**
 * Initializes the date range field
 * @param {string} dateRangeFieldSelector Selector for field want to attach the date range picker to
 * @param {{}} config Additional date range configs
 */
export const InitializeDateRangeFields = (dateRangeFieldSelector, config) => {
    const dateRangeConfig = {...defaultDateRangePickerConfig, ...config};
    InitializeFields(dateRangeFieldSelector, dateRangeConfig);
}

export const GetDateRange = (dateRangeFieldSelector) => {
    const dateRangePicker = document.querySelector(dateRangeFieldSelector);
    const value = dateRangePicker.value;
    const splitValue = value.split("to");
    
    const startDate = parse(splitValue[0].replace(" ", ""), "dd/LL/yyyy", new Date());
    const endDate = parse(splitValue[1].replace(" ", ""), "dd/LL/yyyy", new Date());
    
    return {startDate: startDate, endDate: endDate} 
}



/**
 * Finally initializes the flatpickr instance
 * @param {string} dateFieldSelector 
 * @param {{}} config Configurations as outlined in Flatpickr docs
 */
const InitializeFields = (dateFieldSelector, config) => {
    const timepickers = document.querySelectorAll(dateFieldSelector);
    timepickers.forEach(timepicker => {
        if (dateFields[timepicker.id]) {
            dateFields[timepicker.id].destroy();
        }
        
        dateFields[timepicker.id] = flatpickr(timepicker, config);
        

    });
};

