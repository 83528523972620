import Dropzone from "dropzone";
import {ValidateFileType} from "./MedicalDocument";
import Logger from "js-logger";
import { parse } from "date-fns";
import moment from "moment";

// Initialize the logger
Logger.useDefaults();
if (process.env.NODE_ENV !== "development") {
    Logger.setLevel(Logger.ERROR);
}

let carePlanToBeReplaced = null; 
const EXPIRING_DAYS = 14 ; // This matches what is in the _UserMedicalCarePlan.cshtml

export const ValidateCarePlans = (userMedicalId) => {
    
    let isValid = true;
    let errorMessage = "";

    // Document is required is bound to the dropzone form. We will pull the dropzone element because we can apply the
    // error class to this as well
    const dropzoneElement = document.getElementById(`medical-plan-${userMedicalId}-dropzone`)
    const documentIsRequired = dropzoneElement.dataset.carePlanRequired === "1";
    const planIgnoreCheckbox = document.getElementById(`care-plan-provide-later-${userMedicalId}`)?.checked;
    
    // Remove all errors from the dropzone and revalidate
    dropzoneElement.classList.remove("error");

    // This determines if the plan is to be skipped.
    if(planIgnoreCheckbox){
        return {isValid, errorMessage};
    }
    
    // Pull new files and then pull the existing files
    let files = GetNewMedicalDocuments(userMedicalId);
    if (userMedicalId > 0) files = [...files, ...GetExistingMedicalDocuments(userMedicalId)];
    
    if (files.length === 0 && documentIsRequired) {
        dropzoneElement.classList.add("error");
        isValid = false;
        errorMessage = "A care plan is required for this condition";
    }
    
    // Validate the expiry date
    const validExpiryDates = ValidateExpiryDates(userMedicalId);
    if (!validExpiryDates) {
        isValid = false;
        errorMessage = "Please check the expiry date for the care plans";
    }
    
    return {isValid, errorMessage}

}



export const InitializeCarePlanDropzone = (userMedicalId) => {
    
    // Check that hte dropzone doesn't already exist on this instance
    const careplanDropzone = document.getElementById(`medical-plan-${userMedicalId}-dropzone`);
    if (careplanDropzone.dropzone) return;
    
    Logger.debug("Initializing the care plan dropzone");
    
    // Clear the create thumbnail because we aren't using it and it's sending off server requests
    Dropzone.prototype.createThumbnailFromUrl = function () {};

    // There is a dropzone for care plans that I wish to initialize. It has the ID of medical-plan-@(Model.UserMedicalId)-dropzone
    // in the CSHTML
    new Dropzone(`#medical-plan-${userMedicalId}-dropzone`, {
        url: "/api/StudentProfile/UploadMedication",
        paramName: "file",
        autoProcessQueue: false,
        maxFilesize: 10, // 10MB
        acceptedFiles: ".jpg,.jpeg,.png,.pdf,.doc,.docx,.webp",
        addRemoveLinks: false,
        dictDefaultMessage: "Drop files here to upload",
        dictRemoveFile: "Remove",
        dictFileTooBig: "File is too big ({{filesize}}MB). Max filesize: {{maxFilesize}}MB.",
        dictInvalidFileType: "You can't upload files of this type.",
        dictMaxFilesExceeded: "You can only upload {{maxFiles}} file.",
        previewTemplate: document.querySelector("#dropzone-careplan-preview-template").innerHTML, // TODO: Implement the design for the templaters
        previewsContainer: document.getElementById(`care-plan-${userMedicalId}-list`), // TODO: Implement the container where the preview template will go
        init: function () {
            this.on("addedfile", function (file) {
                // Bind the remove button
                const previewElement = this;
                OnCarePlanAdded(file, previewElement, userMedicalId);
            });
        },
    });

}

/**
 * Initializes the "Does not have an expiry" toggle button
 */
export const BindCarePlanCheckboxes = () => {
    
    document.querySelector("body")
        .addEventListener("click", (event) => {
            
            // Bind the toggle button
            
            if (event.target.classList.contains("care-plan-expiry-toggle")) {
                if(event.target.dataset?.noExpiryDate){
                    event.target.checked = true;
                    return;
                }
                const parentContainer = event
                    .target
                    .closest(".care-plan-expiry-toggle-container ")
                    .parentElement;

                const noExpiry = event.target.checked;
                const provideLater = parentContainer
                    .querySelector(".care-plan-expiry-toggle")?.checked;
                const showExpiryToggle = provideLater || noExpiry;
                
                const expiryDateContainer = event.target
                    .closest(".care-plan-expiry-content")
                    .querySelector(".care-plan-expiry-container");
                
                // Make the expiry date readonly
                const expiryDateInput = expiryDateContainer.querySelector(".care-plan-expiry");
                expiryDateInput.readOnly = showExpiryToggle;
                
                // Remove the datepicker binding
                if (showExpiryToggle) {
                    expiryDateInput.value = "No Expiry Date";
                    window.DestroyDateTimePickerElement(expiryDateInput);
                } else {
                    expiryDateInput.value = "";
                    window.InitializeDateTimePickerElement(expiryDateInput)
                }
                
                const reminderMeContainer = parentContainer.querySelector(".care-plan-reminder-container");
                reminderMeContainer?.classList.toggle("d-none", provideLater);
                
            }
            
            // Bind the reminder me later button
            if (event.target.classList.contains("care-plan-provide-later-toggle")) {
                
                const parentContainer = event
                    .target
                    .closest(".care-plan-reminder-container")
                    .parentElement;
                
                const provideLater = event.target.checked;
                const noExpiry = parentContainer
                    .querySelector(".care-plan-expiry-toggle")
                    .checked;
                const showExpiryToggle = provideLater || noExpiry;

                const expiryDateContainer = event.target
                    .closest(".care-plan-expiry-content")
                    .querySelector(".care-plan-expiry-container");
                expiryDateContainer.classList.toggle("d-none", showExpiryToggle);
                expiryDateContainer.parentElement.classList.toggle("d-none", showExpiryToggle);
                expiryDateContainer.parentElement.classList.toggle("d-flex", !showExpiryToggle);
            }
            
            
        });
}

const OnCarePlanAdded = (file, dropzoneInstance, userMedicalId) => {

    if (!ValidateFileType(file)) throw new Error("Invalid file type for medical care plan document");
    
    const carePlanSkip = document.getElementById(`care-plan-${userMedicalId}-skip-container`);
    if (carePlanSkip) {
        Logger.debug("Hiding checkbox");
        carePlanSkip.classList.add("d-none");
    }
    
    // Bind the download button if there are any
    if (file.url) {
        dropzoneInstance.querySelectorAll(".care-plan-download").forEach((downloadButton) => {
            downloadButton.addEventListener("click", () => {
                window.open(file.url, "_blank");
            });
        });
    }


    // Bind the remove button
    const dzRemoveButtons = file.previewElement.querySelectorAll(".dz-remove");
    Logger.debug("dzRemoveButtons", dzRemoveButtons);
    
    dzRemoveButtons.forEach((removeButton) => {
        removeButton.addEventListener("click", () => {
            
            // Check if documents required
            if (userMedicalId) {
                const documentList = document.querySelector(`#medical-plan-${userMedicalId}-dropzone`);
                const documentsRequired = documentList.dataset.carePlanRequired === "1";

                // TODO: Review the implementation of handling deletion of only file
                if (documentsRequired) {
                    const documentCount = document.querySelectorAll(`#care-plan-${userMedicalId}-list .care-plan-container`).length;
                    if (documentCount <= 1) {
                        window.popup('#modal-medical-careplan-required', '#popupBackground');
                        return;
                    }
                }
            }
            
            dropzoneInstance.removeFile(file);
            // Check if there are any files left
            const files = dropzoneInstance.getAcceptedFiles();
            if (files.length === 0) {
                Logger.debug("No files left. Showing the checkbox");
                if (carePlanSkip) {
                    carePlanSkip.classList.remove("d-none");
                }
            }
            
        });
    });

    // Bind the expiry date pickers, and ignore any existing documents
    try {
        document.querySelectorAll(".care-plan-expiry").forEach((expiryElement) => {
            if(expiryElement.readOnly) return;
            window.InitializeDateTimePickerElement(expiryElement);
        });
    } catch (e) {
        console.error("Failed to initialize the expiry date on the care plan dropzone");
    }


    if (carePlanToBeReplaced !== null) {
        window.RemoveMedicalFile(carePlanToBeReplaced.carePlanId, carePlanToBeReplaced.userMedicalId, false);
        carePlanToBeReplaced = null;
    }

}

/**
 * Get the new medical documents that have been uploaded from DropZone
 * @param {number} userMedicalId The user medical ID
 * @return {*} File Objects with added expiry date
 */
export const GetNewMedicalDocuments = (userMedicalId) => {

    // Attempt to get the dropzone instance
    const dropzoneInstance = document.getElementById(`medical-plan-${userMedicalId}-dropzone`);
    if (!dropzoneInstance) throw new Error("Failed to get the dropzone instance for the medical care plans");

    // Get the file objects from Dropzone
    const newFiles = dropzoneInstance.dropzone.getAcceptedFiles();
    
    // We can associate the file with the index within care-plan-${userMedicalId}-list because the files are rendered
    // in order. Grab all the dz-preview classes
    const previewElements = document.querySelectorAll(`#care-plan-${userMedicalId}-list .dz-preview`);
    
    if (previewElements.length !== newFiles.length) {
        console.error("The number of preview elements does not match the number of files");
    }
    
    const fileDetails = [];
    
    // Associate the expiry date with the file
    // We can assume the new files are in the order of appearance within the Dropzone list
    newFiles.forEach((file, index) => {
        let expiryDateParsed;
        
        // Get the preview element from the dropzone instance
        const previewElement = previewElements[index];
        if (!previewElement) throw new Error("Failed to get the preview element");
        
        // Get the expiry date and the toggle
        const expiryDateElement = previewElement.querySelector(".care-plan-expiry");
        const expiryDateToggle = previewElement.querySelector(".care-plan-expiry-toggle");
        
        // If the toggle is checked, then the expiry date is null
        if (expiryDateToggle.checked) {
            file.hasExpiryDate = false;
            file.expiryDate = null;
            fileDetails.push(file);
            return;
        }
        
        try {
            
            const momentObj = moment(expiryDateElement.value, 'DD/MM/YYYY');
            if (momentObj.isValid()) {
                expiryDateParsed = momentObj.format('YYYY-MM-DD');
            } else {
                expiryDateParsed = null;
            }
            
        } catch (e) {
            expiryDateParsed = null;
        }
        file.expiryDate = expiryDateParsed
        
        fileDetails.push(file);
    });


    return fileDetails;

}

const ValidateIndividualDateTime = (carePlan, isValid) => {
    let elementValid = true;

    const expiryInput = carePlan.querySelector(".care-plan-expiry");
    const expiryInlineError = carePlan.querySelector(".inline-error");
    const expiryInlineWarning = carePlan.querySelector(".inline-warning");
    
    const requiresValidation = expiryInput.dataset["requiresValidation"] === "true";
    if (!requiresValidation) return isValid;

    const expiryDate = expiryInput.value;
    const hasExpiryDate = !(carePlan.querySelector(".care-plan-expiry-toggle").checked || carePlan.querySelector(".care-plan-provide-later-toggle")?.checked);
    
    // Remove the error and warning messages
    expiryInput.classList.remove("error");
    expiryInput.classList.remove("warning");
    expiryInlineError.classList.add("hide");
    expiryInlineWarning.classList.add("hide");
    expiryInlineError.parentElement.classList.add("hide");
    expiryInlineWarning.parentElement.classList.add("hide");

    let errorMessage = "Enter an expiry date";
    if (hasExpiryDate && !expiryDate) {
        // Add an error to the expiry date
        expiryInput.value = "";
        isValid = false;
        elementValid = false;
    }

    if (hasExpiryDate && expiryDate) {
        // Check the date is valid
        try {
            const expiryDateParsed = parse(expiryDate, "dd/MM/yyyy", new Date());
            const expiringDate = new Date(new Date().setDate(new Date().getDate() + EXPIRING_DAYS));
            
            if (expiryDateParsed < new Date()) {
                elementValid = false;
                isValid = false;
                errorMessage = "Document has expired";  
            } else if (expiringDate > new Date()) {
                expiryInlineWarning.classList.remove("hide");
                expiryInlineWarning.parentElement.classList.remove("hide");
                expiryInlineWarning.textContent = "This document will expire soon";
                //expiryInput.classList.add("warning");

            }

        } catch (e) {
            isValid = false;
            elementValid = false;
        }
    }

    if (!elementValid) {
        expiryInlineError.classList.remove("hide");
        expiryInlineError.parentElement.classList.remove("hide");
        expiryInlineError.textContent = errorMessage;
        //expiryInput.classList.add("error");
        
        
        // Add on change listener
        if (!expiryInput.dataset["touched"]) {
            expiryInput.dataset["touched"] = "true";
            expiryInput.addEventListener("change", () => {
                ValidateIndividualDateTime(carePlan, isValid);
            });
        }
        
        
        
    }
    return isValid;
}

/**
 * Get the new medical documents that have been uploaded from DropZone
 * @param {number} userMedicalId The user medical ID
 * @return {*} File Objects with added expiry date
 */
export const ValidateExpiryDates = (userMedicalId) => {
    
    // Get the document list
    const documentList = document.getElementById(`care-plan-${userMedicalId}-list`);
    if (!documentList) throw new Error("Failed to get the document list");
    
    // Get the list of documents
    const carePlans = documentList.querySelectorAll(".care-plan-container");
    if (!carePlans) throw new Error("Failed to get the care plans");
    
    // Loop through the care plans
    let isValid = true;
    carePlans.forEach((carePlan) => {
        isValid = ValidateIndividualDateTime(carePlan, isValid);
        if (!isValid) isValid = false;
    });

    
    return isValid;
}

/**
 * Get the existing medical documents that have been uploaded from DropZone
 * @param {number} userMedicalId
 * @return {*[]} Document model for processing server side
 */
export const GetExistingMedicalDocuments = (userMedicalId) => {

    const existingFiles = [];

    // Loop through the list
    const existingFilesList = document.getElementById(`care-plan-${userMedicalId}-list`);
    if (!existingFilesList) throw new Error("Failed to get the existing files list");

    // Get the existing files
    const existingFilesElements = existingFilesList.querySelectorAll(".existing-care-plan");
    existingFilesElements.forEach((existingFileElement) => {

        const documentId = existingFileElement.dataset.carePlanId;
        const expiryDate = existingFileElement.querySelector(".care-plan-expiry").value;
        const hasExpiryDate = !existingFileElement.querySelector(".care-plan-expiry-toggle").checked;

        let expiryDateParsed;
        if (hasExpiryDate) {
            try {
                const momentObj = moment(expiryDate, 'DD/MM/YYYY');
                if (momentObj.isValid()) {
                    expiryDateParsed = momentObj.format('YYYY-MM-DD');
                } else {
                    expiryDateParsed = null;
                }
                
            } catch (e) {
                Logger.error(`Failed to parse the expiry date for user medical Id ${userMedicalId}`);
                expiryDateParsed = null;
            }
        }

        existingFiles.push({
            D_Id: documentId,
            D_DocumentName: existingFileElement.dataset.carePlanName,
            D_Category: existingFileElement.dataset.carePlanCategory,
            D_ExpiryDate: hasExpiryDate ? expiryDateParsed : null,
            D_IsExpiryRequired: hasExpiryDate
        });
    });

    return existingFiles;
}

export const MarkDocumentForReplace = (carePlanId, userMedicalId) => {
    
    // Trigger the dropzone
    const dropzone = document.getElementById(`medical-plan-${userMedicalId}-dropzone`);
    dropzone.click();
    
    carePlanToBeReplaced = {
        carePlanId,
        userMedicalId
    }
}