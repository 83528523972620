import {
    InitializeMedications,
    CreateMedicationMockFiles,
    BindMedicationNoExpiryDateCheckbox
} from "../Medical/MedicationManagement";
import {
    BindCarePlanCheckboxes,
    GetExistingMedicalDocuments,
    GetNewMedicalDocuments,
    InitializeCarePlanDropzone, MarkDocumentForReplace,
    ValidateCarePlans
} from "../Medical/CarePlanManagement";

// Bind parameters to the window object so we can call in the UI code
window.CreateMedicationFileUI = CreateMedicationMockFiles;
window.InitializeMedicationDropzones = InitializeMedications;
window.InitializeCarePlanDropzone = InitializeCarePlanDropzone;
window.GetNewCarePlanUploads = GetNewMedicalDocuments;
window.GetExistingCarePlanUploads = GetExistingMedicalDocuments;
window.ValidateMedicalCarePLans = ValidateCarePlans;
window.ReplaceCarePlan = MarkDocumentForReplace;

(function() {
    BindCarePlanCheckboxes();
    BindMedicationNoExpiryDateCheckbox();
})();